export const lookupIn = (obj: any) => ({value}: any) => 
  obj[value];

export const userStatuses = {
  new       : 'Новий', 
  active    : 'Активний', 
  suspended : 'Заморожений',
  deleted   : 'Видалений',
};

export const propertySections = {
  1: 'А',
  2: 'Б',
};

export const propertyTypes = {
  apartment : 'Квартира',
  pantry    : 'Комора',
  parking   : 'Паркомісце',
};

export const relationTypes = {
  owner          : 'Власник',
  spouse         : 'Дружина',
  relative       : 'Родич',
  representative : 'Представник',
  tenant         : 'Орендар',
};
