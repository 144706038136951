import React, { useState } from 'react';
import Alert from '@material-ui/lab/Alert';

interface ResponseErrorProps {
  error?: {
    code: number;
    message: string;
    errors: {
      field: string;
      message: string;
    }[]
  }
}

export const ResponseError = ({error}: ResponseErrorProps) =>
  !error ? null :
  !error.errors ? <Alert severity="error">{JSON.stringify(error)}</Alert> :
  <>
    { error.errors.map((e, i) =>
        <Alert severity="error" key={`err-${i}`}>
            {e.message}
        </Alert>
    ) }
  </>
