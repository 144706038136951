import React, { useEffect, createContext, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { UsersList } from './UsersList';
import { parseCognitoWebResponse, checkSession, logout } from './auth';

export const SessionContext = createContext({session: {idToken: null}});

const Callback = () => {
  const { href } = window.location;
  useEffect(() => {
    parseCognitoWebResponse(href)
      .then(() => {
        window.location.href = '/';
      }).catch(console.error);
  }, [href]);
  return null;
};

function App() {
  const { href } = window.location;
  const [session, setSession] = useState({idToken: null});
  useEffect(() => {
    checkSession().then(s => setSession(s as any));
  }, [href]);
  return (
    <SessionContext.Provider value={{session}}>
    <Router>
      <React.Fragment>
        <CssBaseline />
        <Container>
        <Switch>
            <Route path="/users/create">
              Create
            </Route>
            <Route path="/users">
              <UsersList />
            </Route>
            <Route path="/logout">
              {() => { logout() }}
            </Route>
            <Route path="/callback">
              <Callback />
            </Route>
            <Route path="/">
              <Link to="/users">Users</Link>
            </Route>
          </Switch>
        </Container>
      </React.Fragment>
    </Router>
    </SessionContext.Provider>
  );
}

export default App;
