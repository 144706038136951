export const request = (method: string, url: string, token?: string, body?: any) => !token ? Promise.reject('Unauthorized')
  : fetch(`${process.env.REACT_APP_API_ENDPOINT}${url}`, {
    method,
    body,
    headers: {
      'Authorization': token,
      'Accept-Encoding': 'gzip, deflate, br',
      'Content-Type': 'application/json',
    }
  }).then(async res => {
    const body = await res.json();
    if(!res.ok) throw body;
    return body;
  });
