import React, { useState, useEffect, useContext } from 'react';
import { User } from './User';
import { Property } from './Property';
import { AgGridReact } from 'ag-grid-react';
import Grid from '@material-ui/core/Grid';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { SessionContext } from './App';
import { get } from 'lodash';
import { PropertiesList } from './PropertiesList';
import { request } from './request';
import { FullDialog } from './FullDialog';
import { ActionsBar } from './ActionsBar';
import UserFormDialog from './UserFormDialog';
import PropertyFormDialog from './PropertyFormDialog';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import { lookupIn, userStatuses } from './constants';
import {useDebounce} from '@react-hook/debounce';
import { v4 as uuid } from 'uuid';

const combineUserName = ({data: {lastName, firstName, patName}}: any) =>
  [lastName, firstName, patName].join(' ');

const columnDefs = [
  { field: "name"      , headerName: "ПІБ"         , pinned: 'left', valueGetter: combineUserName},
  { field: "phone"     , headerName: "Телефон"     , pinned: 'left', width: 130},
  { field: "countProps", headerName: "Об'єктів"    , width: 100,},
  { field: "space"     , headerName: "Заг. Площа"  , width: 120},
  { field: "email"     , headerName: "Email"       ,},
  { field: "status"    , headerName: "Status"      , width: 70, valueFormatter: lookupIn(userStatuses)},
  { field: "id"        , headerName: "ID"          , width: 300},
];

export function UsersList() {
    const [page, setPage] = useState(1);
    const [usersList, setUsersList] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<User|undefined>();
    const [selectedProperties, setSelectedProperties] = useState<Property[]>([]);
    const [editedProperty, setEditedProperty] = useState<Property|undefined>();
    const [isLoading, setIsLoading] = useState(true);
    const [updated, setUpdated] = useState(new Date());
    const [userDialogOpen, setUserDialogOpen] = useState(false);
    const [propDialogOpen, setPropDialogOpen] = useState(false);
    const sessionContext = useContext(SessionContext);
    const token = get(sessionContext, 'session.idToken.jwtToken');
    const [searchString, setSearchString] = useDebounce('', 500);
    useEffect(() => {
      if(!token) return;
      request('GET', '/users' + `?search=${searchString}`, token)
        .then(list => {
          setUsersList(list);
          setIsLoading(false);
        })
        .catch(error => console.log(error));
    }, [page, sessionContext, updated, searchString]);

    const updateUser = ({id, ...body}: User) =>
      request('PUT', `/users/${id}`, token, JSON.stringify(body))
        .then(user => {
          setUpdated(new Date());
          setSelectedUser(user);
          return user;
        });

    const createUser = ({id, ...body}: User): Promise<User> => 
      request('POST', `/users/`, token, JSON.stringify(body))
        .then((user: User) => {
          setUsersList([user, ...usersList]);
          return user;
        });

    const updateProperty = ({id, ...body}: Property) =>
      selectedUser ?
      request('PUT', `/users/${selectedUser.id}/properties/${id}`, token, JSON.stringify(body))
        .then(user => {
          setUpdated(new Date());
          return user;
        }) : Promise.reject();

    const deleteProperties = (properties: Property[]) =>
      Promise.all(
        properties.map(({id}) =>
          request('DELETE', `/properties/${id}`, token)
        )).then((res) => {
          console.log('SUCCESS DELETED', res);
          setUpdated(new Date());
        });

    const createProperty = ({id, ...body}: Property): Promise<Property> => 
      !selectedUser ? Promise.reject('Property not selected') :
      request('POST', `/users/${selectedUser.id}/properties`, token, JSON.stringify(body))
        .then((property: Property) => {
          setUpdated(new Date());
          return property;
        });
    
    const uploadFile = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener('loadend', () => {
          request('POST', '/requestUploadURL', token,
            JSON.stringify({
              name: uuid(),
              type: file.type
            })
          )
          .then((json) => {
            return fetch(json.uploadURL, {
              method: "PUT",
              body: new Blob([reader.result as BlobPart], {type: file.type})
            })
          })
          .then(({url}) => {
            console.log('Successfully uploaded');
            return url.split('?')[0];
            // list.appendChild(uploadedFileNode);
          })
          .then(resolve)
          .catch(reject);
        });
        reader.readAsArrayBuffer(file);
      })
    }

    return (
      <>
        <ActionsBar
          onSearchChange={setSearchString}
          actions={[
            {
              label: 'Додати Користувача',
              onClick: () => setUserDialogOpen(true),
            }
        ]}>
          {/* <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton> */}
        </ActionsBar>
        <Grid container>
          <Grid item xs={12}>
            <div className="ag-theme-balham" style={ {height: '90vh', width: '100%'} }>
              <AgGridReact
                  columnDefs={columnDefs}
                  enableRangeSelection={true}
                  suppressMultiRangeSelection={true}
                  modules={AllModules as any}
                  rowData={usersList}
                  onRowDoubleClicked={({data}: {data: User}) => setSelectedUser(data)}
                  defaultColDef={{
                    flex: 1,
                    sortable: true,
                    enablePivot: true,
                  }}
                  sideBar={{
                    toolPanels: [
                      {
                        id: 'columns',
                        labelDefault: 'Columns',
                        labelKey: 'columns',
                        iconKey: 'columns',
                        toolPanel: 'agColumnsToolPanel',
                        toolPanelParams: {
                          suppressRowGroups: true,
                          suppressValues: true,
                          suppressPivots: true,
                          suppressPivotMode: true,
                          suppressSideButtons: true,
                          suppressColumnFilter: true,
                          suppressColumnSelectAll: true,
                          suppressColumnExpandAll: true,
                        },
                      },
                    ],
                    defaultToolPanel: 'columns',
                  }}
                >
              </AgGridReact>
            </div>
          </Grid>

          { !!selectedUser &&
            <FullDialog
              open={true}
              onClose={() => setSelectedUser(undefined)}
              actions={[
                {
                  label: combineUserName({data: selectedUser}),
                  onClick: () => setUserDialogOpen(true),
                },
                {
                  label: 'Додати об\'єкт',
                  onClick: () => setPropDialogOpen(true),
                },
                ...(selectedProperties?.length ? [{
                  label: 'Видалити об\'єкт' + (selectedProperties?.length > 1 ? 'и' : ''),
                  onClick: () => deleteProperties(selectedProperties),
                }] : [])
              ]}
            >
              <PropertiesList
                user={selectedUser}
                onEdit={setEditedProperty}
                onSelect={setSelectedProperties}
                updated={updated}
                setUpdated={setUpdated}
              />
            </FullDialog>
          }

          <UserFormDialog
            user={selectedUser && selectedUser}
            open={userDialogOpen}
            setOpen={setUserDialogOpen}
            onCreate={createUser}
            onUpdate={updateUser}
          />

          <PropertyFormDialog
            property={editedProperty}
            open={!!editedProperty || propDialogOpen}
            setClose={() => { setPropDialogOpen(false); setEditedProperty(undefined); }}
            onCreate={createProperty}
            onUpdate={updateProperty}
            uploadFile={uploadFile}
          />
        </Grid>
      </>
    )
}


