import React from 'react';
import Button from '@material-ui/core/Button';

export interface ActionButtonProps {
  label: string;
  onClick: any;
}

export const ActionButton = ({ label, onClick }: {
  label: string;
  onClick: () => any;
}) => (
  <Button autoFocus color="inherit" onClick={onClick}>
    {label}
  </Button>
);
