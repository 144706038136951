import React, { useEffect, useState, useContext } from 'react';
import { get } from 'lodash';
import { AgGridReact } from 'ag-grid-react';
import { SessionContext } from './App';
import { User } from './User';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { NumericCellEditor } from './NumericCellEditor';
import { request } from './request';
import { Property } from './Property';
import { lookupIn, propertyTypes, propertySections, relationTypes } from './constants';
import { AllModules } from '@ag-grid-enterprise/all-modules';

interface PropertiesProps {
  user: User;
  onSelect: (selected: Property[]) => any;
  onEdit: (selected: Property|undefined) => any;
  setUpdated: (date: Date) => any;
  updated: Date;
}

const typeLetter = (type: string) => lookupIn({
  apartment: '',
  pantry: 'К',
  parking: 'П'
})({value: type});

const combinePropertyNumber = ({data: {section, floor, projectNum, type}}: any) =>
  `${lookupIn(propertySections)({value: section})}${floor}/${typeLetter(type)}${projectNum}`;

const columnDefs = [
  { field: "type"               , headerName: "Тип"              , valueFormatter: lookupIn(propertyTypes)},
  { field: "addressNumber"      , headerName: "Номер"            },
  { field: "relationType"       , headerName: "Відношення"       , valueFormatter: lookupIn(relationTypes)},
  { field: "totalSpace"         , headerName: "Заг. Площа"       },
  { field: "pnum"               , headerName: "Проектний номер"  , valueGetter: combinePropertyNumber},
  { field: "floor"              , headerName: "Поверх"           },
  { field: "projectNum"         , headerName: "Пр. номер"        },
  { field: "confirmDocument"    , headerName: "Документ"         },
  { field: "documentLinks"      , headerName: "Посилання на док" },
  { field: "livingSpace"        , headerName: "Житл. Площа"      },
  { field: "id"                 , headerName: "ID"               },
];

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

export function PropertiesList({user, onSelect, onEdit, updated, setUpdated}: PropertiesProps) {
  const sessionContext = useContext(SessionContext);
  const [propertiesList, setPropertiesList] = useState<Property[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [updated, setUpdated] = useState(new Date());
  const [selected, setSelected] = useState<Property[]|null>([]);
  const classes = useStyles();
  const token = get(sessionContext, 'session.idToken.jwtToken');

  useEffect(() => {
    if(!token) return;
    request('GET', `/users/${user.id}/properties`, token)
      .then(list => {
        setPropertiesList(list);
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, [user.id, sessionContext, updated]);

  const updateProperty = ({data: {id, ...body}}: {data: User}) =>
      request('PUT', `/properties/${id}`, token, JSON.stringify(body))
        .then(() => setUpdated(new Date()));

  return (
    <div className="ag-theme-balham" style={ {height: '70vh', width: '100%'} }>
      <AgGridReact
          columnDefs={columnDefs}
          components={{
            numericCellEditor: NumericCellEditor as any,
          }}
          rowData={propertiesList}
          modules={AllModules as any}
          suppressMultiRangeSelection={true}
          enableRangeSelection={true}
          defaultColDef={{
            flex: 1,
            sortable: true,
            enablePivot: true,
          }}
          rowSelection='multiple'
          onSelectionChanged={({api}) => onSelect(api.getSelectedRows())}
          onRowDoubleClicked={({data}: {data: Property}) => onEdit(data)}
        >
      </AgGridReact>
      <code>
        <pre>
          {JSON.stringify(user.info, null, 2)}
        </pre>
      </code>
    </div>
  );
}
