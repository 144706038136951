import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import { pickBy, identity } from 'lodash';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import { User } from './User';
import { TextMaskCustom } from './TextMaskCustom';
import { ResponseError } from './ResponseError';

interface UserFormDialogProps {
  user?: User;
  open: boolean;
  setOpen: (value: boolean) => any;
  onCreate: (data: any) => Promise<User>;
  onUpdate: (data: any) => Promise<User>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);

const clearObj = (obj: any) =>
  obj ? pickBy(obj, identity) : {};


export default function UserFormDialog({user, open, setOpen, onCreate, onUpdate}: UserFormDialogProps) {
  const onSubmit = user ? onUpdate : onCreate;
  const classes = useStyles();
  const [error, setError] = useState(undefined);

  const handleClose = () => {
    setOpen(false);
    setError(undefined);
  };

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      patName: '',
      phone: '',
      email: '',
      ...clearObj(user)
    },
    enableReinitialize: true,
    onSubmit: data => {
      setError(undefined);
      return onSubmit(data)
        .then(handleClose)
        .catch(setError)
    },
  });

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form className={classes.root} onSubmit={formik.handleSubmit}>
      <DialogTitle id="form-dialog-title">{user ? 'Редагувати' : 'Створити нового користувача'}</DialogTitle>
      <DialogContent>
        <ResponseError error={error} />

        <DialogContentText>
          Введіть номер телефона в міжнародному форматі: +380123456789
        </DialogContentText>

        <Input
          autoFocus
          style={{width:'100%'}}
          id="phone"
          name="phone"
          onChange={formik.handleChange}
          inputComponent={TextMaskCustom as any}
          value={formik.values.phone}
        />
        <TextField
          id="email"
          name="email"
          type="email"
          label="Ел. адреса"
          onChange={formik.handleChange}
          value={formik.values.email}
          style={{width:'100%'}}
        />
        <TextField
          id="lastName"
          label="Прізвище"
          name="lastName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.lastName}
        />
        <TextField
          id="firstName"
          label="Ім'я"
          name="firstName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.firstName}
        />
        <TextField
          id="patName"
          label="По-батькові"
          name="patName"
          type="text"
          onChange={formik.handleChange}
          value={formik.values.patName}
        />
        
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Відміна
        </Button>
        <Button type="submit" color="primary">
          Зберегти
        </Button>
      </DialogActions>
      </form>
    </Dialog>
  );
}
