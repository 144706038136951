import { CognitoAuth } from 'amazon-cognito-auth-js';
// import { CognitoUserPool } from 'amazon-cognito-identity-js'
// import { config as AWSConfig, CognitoIdentityCredentials } from 'aws-sdk'

const noAuth = () => process.env.REACT_APP_NO_AUTH === 'true';

const authData = {
	ClientId           : String(process.env.REACT_APP_CLIENT_ID),
	UserPoolId         : String(process.env.REACT_APP_USER_POOL_ID),
	AppWebDomain       : String(process.env.REACT_APP_APP_WEB_DOMAIN),
	TokenScopesArray   : ['phone', 'openid'],
	RedirectUriSignIn  : String(process.env.REACT_APP_REDIRECT_URI_SIGN_IN),
	RedirectUriSignOut : String(process.env.REACT_APP_REDIRECT_URI_SIGN_OUT),
	// IdentityProvider : '<TODO: add identity provider you want to specify>', // e.g. 'Facebook',
	// AdvancedSecurityDataCollectionFlag : true,
  Storage: localStorage,
};

export const createAuth = (callback: (auth: CognitoAuth) => void) => {
  const auth = new CognitoAuth(authData);
  // auth.useCodeGrantFlow();
  return new Promise((resolve, reject) => {
    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: resolve,
      onFailure: reject,
    }
    callback(auth);
  })
};

export const parseCognitoWebResponse = (href: string) => {
  return createAuth(auth => {
    auth.parseCognitoWebResponse(href);
  })
};

export const checkSession = () => {
  return noAuth() ?
    Promise.resolve({idToken: {jwtToken: '123'}}) :
    createAuth(auth => {
      auth.getSession();
    })
};

export const logout = () => {
  return createAuth(auth => {
    auth.signOut();
  })
};
