import React, { useState, useEffect, ChangeEvent } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import { pickBy, identity } from 'lodash';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Property } from './Property';
import { ResponseError } from './ResponseError';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dropzone from "react-dropzone";

interface PropertyFormDialogProps {
  property?: Property;
  open: boolean;
  setClose: () => any;
  onCreate: (data: any) => Promise<Property>;
  onUpdate: (data: any) => Promise<Property>;
  uploadFile: (data: any) => Promise<string>;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    formControl: {
      minWidth: 120,
    },
  }),
)

const dropzoneStyle = {
  width: "100%",
  height: "auto",
  borderWidth: 2,
  borderColor: "rgb(102, 102, 102)",
  borderStyle: "dashed",
  borderRadius: 5,
  paddingLeft: 20,
  marginBottom: 10,
  marginTop: 10
}

const clearObj = (obj: any) =>
  obj ? pickBy(obj, identity) : {};


export default function UserFormDialog({property, open, setClose, onCreate, onUpdate, uploadFile}: PropertyFormDialogProps) {
  const onSubmit = property ? onUpdate : onCreate;
  const classes = useStyles();
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    setClose();
    setError(undefined);
  };

  const formik = useFormik({
    initialValues: {
      addressNumber: undefined,
      section: undefined,
      floor: undefined,
      projectNum: undefined,
      type: 'apartment',
      confirmDocument: undefined,
      documentLinks: [],
      totalSpace: undefined,
      livingSpace: undefined,
      relationType: 'owner',
      files: [],
      ...clearObj(property)
    },
    enableReinitialize: true,
    onSubmit: (data, {resetForm}) => {
      setError(undefined);
      return onSubmit(data)
        .then(handleClose)
        .then(() => resetForm({}))
        .catch(setError)
    },
  });

  const onDrop = (acceptedFiles: File[]) => {
    setLoading(true);
    Promise.all(acceptedFiles.map(uploadFile))
      .then(urls => {
        formik.setFieldValue('documentLinks', urls);
      })
      .catch(console.error)
      .finally(() => { setLoading(false); });
    // formik.setFieldValue("files", [...formik.values.files, ...acceptedFiles]);
  }

  const changeSelectValue = (field: string) =>
    ({target}: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>) =>
      formik.setFieldValue(field, target.value);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <form className={classes.root} onSubmit={formik.handleSubmit}>
      <DialogTitle id="form-dialog-title">{property ? 'Редагувати' : 'Створити новий об\'єкт'}</DialogTitle>
      <DialogContent>
        <ResponseError error={error} />
        <FormControl style={{width: formik.values.type === 'apartment' ? '34%' : '50%'}}>
          <InputLabel id="type-label">Тип</InputLabel>
          <Select
            labelId="type-label"
            id="type"
            value={formik.values.type}
            style={{width:'100%'}}
            onChange={changeSelectValue('type')}
          >
            <MenuItem value='apartment'>Квартира</MenuItem>
            <MenuItem value='pantry'>Комора</MenuItem>
            <MenuItem value='parking'>Паркомісце</MenuItem>
          </Select>
        </FormControl>

        <FormControl style={{width: formik.values.type === 'apartment' ? '33%' : '50%'}}>
          <InputLabel id="type-label">Відношення</InputLabel>
          <Select
            labelId="type-label"
            id="relationType"
            value={formik.values.relationType}
            style={{width:'100%'}}
            onChange={changeSelectValue('relationType')}
          >
            <MenuItem value='owner'>Власник</MenuItem>
            <MenuItem value='spouse'>Дружина/Чоловік</MenuItem>
            <MenuItem value='relative'>Родич</MenuItem>
            <MenuItem value='representative'>Представник</MenuItem>
            <MenuItem value='tenant'>Орендар</MenuItem>
          </Select>
        </FormControl>

        { formik.values.type === 'apartment' &&
          <TextField
            id="addressNumber"
            name="addressNumber"
            type="number"
            label="Номер за адресою"
            onChange={formik.handleChange}
            value={formik.values.addressNumber}
            style={{width:'33%'}}
          />
        }

        <FormControl style={{width:'34%'}}>
          <InputLabel id="section-label">Секція</InputLabel>
          <Select
            labelId="section-label"
            id="section"
            value={formik.values.section}
            onChange={changeSelectValue('section')}
          >
            <MenuItem value={1}>А</MenuItem>
            <MenuItem value={2}>Б</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{width:'33%'}}>
          <TextField
            id="floor"
            name="floor"
            type="number"
            label="Поверх"
            onChange={formik.handleChange}
            value={formik.values.floor}
          />
        </FormControl>

        <FormControl style={{width:'33%'}}>
          <TextField
            id="projectNum"
            name="projectNum"
            type="number"
            label="Номер за проектом"
            onChange={formik.handleChange}
            value={formik.values.projectNum}
          />
        </FormControl>

        <TextField
          id="totalSpace"
          name="totalSpace"
          type="number"
          label="Загальна площа"
          onChange={formik.handleChange}
          value={formik.values.totalSpace}
          style={{width:'50%'}}
        />

        <TextField
          id="livingSpace"
          name="livingSpace"
          type="number"
          label="Житлова площа"
          onChange={formik.handleChange}
          value={formik.values.livingSpace}
          style={{width:'50%'}}
        />

        <TextField
          id="confirmDocument"
          label="Документ що підтверджує право власності"
          multiline
          rows={4}
          value={formik.values.confirmDocument}
          onChange={formik.handleChange}
          style={{width:'100%'}}
          variant="outlined"
        />

        { isLoading ? 'Loading...' :
          <Dropzone accept="image/*" onDrop={onDrop}>
            {({getRootProps, getInputProps}: any) => (
              <section style={dropzoneStyle}>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p>Перетягніть файли, або клікніть сюди</p>
                </div>
              </section>
            )}
          </Dropzone>
        }

        {formik.values.documentLinks && formik.values.documentLinks.map(url =>
          <img key={url} src={url} style={{width: '100%', objectFit: 'cover'}} />
        )}

      </DialogContent>
      { !isLoading &&
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Відміна
          </Button>
          <Button type="submit" color="primary">
            Зберегти
          </Button>
        </DialogActions>
      }
      </form>
    </Dialog>
  );
}
