import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ActionsBar } from './ActionsBar';

interface FullDialogProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => any;
  actions: {
    label: string;
    onClick: any;
  }[];
}

export function FullDialog({ children, open, onClose, actions }: FullDialogProps) {
  return (
    <Dialog fullScreen open={open} onClose={onClose} TransitionComponent={Transition}>
      <ActionsBar actions={actions}>
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </ActionsBar>
      {children}
    </Dialog>
  );
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


